import { useDispatch, useSelector } from 'react-redux';

import { resetParams } from 'dpl/actions/query_params';
import {
  ANALYTICS,
  ANALYTICS_VIEW,
  INITIAL_INQUIRY_ID,
  INITIAL_INQUIRY_SOURCE,
  LITTER_ID,
  ONE_CLICK_APPLY_MODAL_OPEN,
  PUPPY_ID
} from 'dpl/constants/query_params';
import { get } from 'dpl/shared/utils/object';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { nameUnnamedPuppies } from 'dpl/shared/utils/breeder_app';
import {
  singleBreederProfileResourceDefinition,
  singleLitterResourceDefinition
} from 'dpl/shared/utils/tptConnectResourceDefinitions';

import { INITIAL_INQUIRIES_FIELD_NAME } from '../utils/constants';
import { INITIAL_INQUIRY_SOURCE_VIEWS } from '../utils/analytics';
import useInitialInquiryContext from './useInitialInquiryContext';

export default function useInitialInquiry() {
  const dispatch = useDispatch();
  const queryParams = useSelector(state => state.queryParams);
  const currentUser = useCurrentUser();
  const context = useInitialInquiryContext();

  const { analytics, onClose } = context;
  const currentLitterId = context.litterId ?? queryParams[LITTER_ID];
  const currentPuppyId = context.puppyId ?? queryParams[PUPPY_ID];

  const breederProfileId =
    context.breederProfileId ?? queryParams[ONE_CLICK_APPLY_MODAL_OPEN];

  const inquirySource =
    context.inquirySource ?? queryParams[INITIAL_INQUIRY_SOURCE];

  const { value: breederProfile, isFetching: isFetchingBreederProfileData } =
    useResourceQuery({
      ...singleBreederProfileResourceDefinition(breederProfileId),
      enabled: Boolean(breederProfileId)
    });

  const { value: litter, isFetching: isFetchingLitterData } = useResourceQuery({
    ...singleLitterResourceDefinition(breederProfileId, currentLitterId),
    enabled: Boolean(breederProfileId && currentLitterId)
  });

  const puppies = nameUnnamedPuppies(litter?.data.puppies ?? []);
  const currentPuppy = puppies.find(p => p.id === Number(currentPuppyId)) ?? {};

  const initialInquiries = get(currentUser, INITIAL_INQUIRIES_FIELD_NAME, []);

  const alreadyApplied = initialInquiries.some(
    inquiry => inquiry.breeder_profile_id.toString() === breederProfileId
  );

  const isFetching = Boolean(
    isFetchingBreederProfileData || isFetchingLitterData
  );

  const isModalOpen = Boolean(
    breederProfileId && currentLitterId && currentPuppyId
  );

  function closeModal() {
    if (typeof onClose === 'function') {
      onClose();
    } else {
      const otherParams = [];

      if (INITIAL_INQUIRY_SOURCE_VIEWS.includes(inquirySource)) {
        // If the quiz modal was started from a puppy search result
        // or favorite puppy card (and not the Puppy modal), reset these
        // other params so that the Puppy modal remains closed.
        otherParams.push(INITIAL_INQUIRY_SOURCE, PUPPY_ID, LITTER_ID);
      }

      dispatch(
        resetParams(
          ANALYTICS,
          ANALYTICS_VIEW,
          INITIAL_INQUIRY_ID,
          ONE_CLICK_APPLY_MODAL_OPEN,
          ...otherParams
        )
      );
    }
  }

  return {
    alreadyApplied,
    analytics,
    isModalOpen,
    closeModal,
    isFetching,
    litter: litter?.data ?? {},
    breederProfile,
    currentPuppy
  };
}
