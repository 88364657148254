import { useDispatch, useSelector } from 'react-redux';

import { resetParams } from 'dpl/actions/query_params';
import {
  BUYER_PROFILE_MODAL_OPEN,
  BUYER_QUIZ_UPDATE,
  RETURN_URL,
  LITTER_ID,
  PUPPY_ID
} from 'dpl/constants/query_params';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import { get } from 'dpl/shared/utils/object';

import { getEditBuyerProfileQuizQueryDefinition } from '../utils/queryDefinitions';

export default function useBuyerProfileQuiz() {
  const dispatch = useDispatch();
  const queryParams = useSelector(state => state.queryParams);
  const currentUser = useCurrentUser();

  const buyerProfileId = get(currentUser, 'value.data.buyer_profile_id');

  const conversationId = queryParams[BUYER_PROFILE_MODAL_OPEN];
  const isUpdating = queryParams[BUYER_QUIZ_UPDATE] === 'true';

  const { value: quizData, isFetching: isFetchingQuizOptionsData } =
    useResourceQuery({
      ...getEditBuyerProfileQuizQueryDefinition({
        buyerProfileId,
        conversationId
      }),
      enabled: Boolean(buyerProfileId) && Boolean(conversationId)
    });

  const isFetching = Boolean(isFetchingQuizOptionsData);
  const isModalOpen = Boolean(conversationId);

  async function closeModal() {
    dispatch(
      resetParams(BUYER_PROFILE_MODAL_OPEN, RETURN_URL, LITTER_ID, PUPPY_ID)
    );
  }

  return {
    conversationId,
    breederProfile: quizData.breeder_profile,
    currentUser,
    puppyName: quizData.puppy_name,
    isFetching,
    isModalOpen,
    closeModal,
    isUpdating,
    buyerProfileData: quizData.buyer_profile,
    quizOptionsData: quizData.options
  };
}
