import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'validate.js';
import { FeaturesReady } from '@growthbook/growthbook-react';

import { pushParams } from 'dpl/actions/query_params';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';
import FullPageQuiz, {
  PROGRESS_BAR_VARIANTS
} from 'dpl/components/FullPageQuiz';
import { FULL_PAGE_QUIZ_QUESTION_VARIANTS } from 'dpl/components/FullPageQuiz/FullPageQuizQuestion';
import LoadingWrapper from 'dpl/components/LoadingWrapper';
import OverlayModal from 'dpl/components/OverlayModal';
import UserAuthModalForgotPasswordForm from 'dpl/components/UserAuthModal/UserAuthModalForgotPasswordForm';
import UserAuthModalLoginForm from 'dpl/components/UserAuthModal/UserAuthModalLoginForm';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_USER_JOURNEYS,
  ANALYTICS_VIEWS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEW_FEATURE_LOCATIONS
} from 'dpl/constants/analytics';
import {
  ANALYTICS_VIEW,
  BUYER_PROFILE_MODAL_OPEN
} from 'dpl/constants/query_params';
import {
  SIGNUP_FORM_TYPES,
  TOAST_NOTIFICATIONS_TYPES,
  USER_TYPES
} from 'dpl/shared/constants/shared';
import { createUserMutationDefinition } from 'dpl/shared/buyer/utils/queryDefinitions';
import useResourceMutation from 'dpl/shared/fetching/hooks/useResourceMutation';
import useForm from 'dpl/shared/form/hooks/useForm';
import FormContext from 'dpl/shared/form/utils/context';
import useToastNotifications from 'dpl/shared/hooks/useToastNotifications';
import { conversationCreateMutationDefinition } from 'dpl/shared/messaging/utils/queryDefinitions';
import { get, removeEmptyValues } from 'dpl/shared/utils/object';
import { pick } from 'dpl/shared/utils';
import { CONSTRAINT_TYPES } from 'dpl/shared/validations/constraints';
import { setAnalyticsOrigin } from 'dpl/shared/utils/setAnalyticsOrigin';
import { sendRudderstackEvent } from 'dpl/util/analytics';

import useInitialInquiry from '../hooks/useInitialInquiry';
import {
  trackStepAnswer,
  trackStepView,
  trackInitialInquirySubmission,
  trackAccountCreation,
  INITIAL_INQUIRY_SOURCE_VIEWS
} from '../utils/analytics';
import {
  FORM_ACCOUNT_EMAIL,
  FORM_ACCOUNT_FIRST_NAME,
  FORM_ACCOUNT_LAST_NAME,
  FORM_ACCOUNT_PASSWORD,
  FORM_ACCOUNT_PHONE_NUMBER,
  QUIZ_QUESTION_BUYER_NAME,
  QUIZ_QUESTION_BUYER_PASSWORD,
  QUIZ_QUESTION_BUYER_PHONE_NUMBER,
  QUIZ_QUESTION_BUYER_EMAIL,
  ERROR_MESSAGE_ALREADY_APPLIED,
  ERROR_MESSAGE_GENERIC,
  QUIZ_QUESTION_INTRO,
  BUTTON_CONTINUE_LABEL
} from '../utils/constants';
import InitialInquiryContext from '../utils/context';
import IconSet from './IconSet';
import QuizEmailQuestion from './QuizEmailQuestion';
import QuizNameQuestion from './QuizNameQuestion';
import QuizPasswordQuestion from './QuizPasswordQuestion';
import QuizPhoneQuestion from './QuizPhoneQuestion';
import QuizIntroQuestion from './QuizIntroQuestion';
import BuyerProfileQuiz from './BuyerProfileQuiz';

const { LOGIN, FORGOT_PASSWORD } = SIGNUP_FORM_TYPES;
const WIDE_QUIZ_QUESTION = FULL_PAGE_QUIZ_QUESTION_VARIANTS.WIDE;

function InitialInquiryQuizContent() {
  const {
    analytics,
    isModalOpen,
    closeModal,
    isFetching,
    breederProfile,
    currentPuppy,
    litter
  } = useInitialInquiry();

  /*
    we need the search results page location to get
    recommended puppies on the initial inquiry success page
  */
  const { queryParams } = useSelector(state => state);

  /*
    if user is submitting an initial inquiry from the recommended puppies
    on the success page, the state/city values will be set as query params
    vs server value from search results page
  */
  const currentUser = useCurrentUser();
  const { pushToastNotification } = useToastNotifications();
  const [signupFormType, setSignupFormType] = useState(null);
  const [conversationId, setConversationId] = useState(null);

  const { mutateAsync: createConversation } = useResourceMutation(
    conversationCreateMutationDefinition
  );

  const { mutateAsync: createUserAccount } = useResourceMutation(
    createUserMutationDefinition
  );

  const profileIds = {
    breeder_profile_id: breederProfile?.id,
    buyer_profile_id: get(currentUser, 'value.data.buyer_profile_id')
  };

  let orderedSteps = [];

  const dispatch = useDispatch();

  const {
    contextValue,
    formState,
    formErrors,
    reset,
    handleFormFieldChange,
    handleSubmission,
    isValidating
  } = useForm({
    defaultFormState: {
      account: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        password: ''
      },
      with: {
        breeder_profile_id: breederProfile?.id
      },
      conversation_message: {
        message_text: `Hi! I’d love to learn more about ${currentPuppy.name} from ${litter.name}’s litter.`
      },
      puppy_preferences: {
        puppy_id: currentPuppy?.id,
        color: [],
        gender: '',
        size: []
      }
    },
    onSubmit: async formData => {
      try {
        let userAccount = null;

        if (!currentUser.isLoggedIn) {
          userAccount = await createUserAccount({
            body: {
              user_type: USER_TYPES.BUYER,
              user: formData.account
            }
          });

          trackAccountCreation(userAccount);
        }

        const conversation = await createConversation({
          body: {
            conversation: pick(formData, [
              'conversation_message',
              'with',
              'puppy_preferences'
            ])
          }
        });

        await currentUser.refetch();

        const lastStep = orderedSteps[orderedSteps.length - 1];

        if (lastStep.inputName) {
          trackStepAnswer({
            step: lastStep,
            formState,
            properties: profileIds
          });
        }

        sendRudderstackEvent(ANALYTICS_EVENTS.BUTTON_CLICKED, {
          button_cta: BUTTON_CONTINUE_LABEL,
          user_journey:
            ANALYTICS_USER_JOURNEYS.BUYER_ACTIVATION_INITIAL_INQUIRY,
          view: 'Info Request'
        });

        const buyerProfileId =
          get(currentUser, 'value.data.buyer_profile_id') ||
          get(userAccount, 'data.user.buyer_profile_id');

        const computedAnalytics = {
          breeder_preferred_status:
            breederProfile?.preferred_qualification?.status,
          litter_id: litter?.id,
          puppy_id: currentPuppy?.id,
          view: ANALYTICS_VIEWS.INITIAL_INQUIRY_FORM,
          view_feature: ANALYTICS_VIEW_FEATURES.SEND_REQUEST_BUTTON,
          view_feature_location: ANALYTICS_VIEW_FEATURE_LOCATIONS.CTA,
          ...analytics
        };

        if (queryParams[ANALYTICS_VIEW] === ANALYTICS_VIEWS.PUPPY_MODAL) {
          setAnalyticsOrigin({
            originView: queryParams[ANALYTICS_VIEW],
            originViewFeature: ANALYTICS_VIEW_FEATURES.REQUEST_INFO_BUTTON
          });
        }

        const convoId = get(conversation, 'data.conversation.id');

        trackInitialInquirySubmission({
          analytics: computedAnalytics,
          formState: formData,
          buyerProfileId,
          conversationId: convoId
        });

        dispatch(pushParams({ [BUYER_PROFILE_MODAL_OPEN]: convoId }));
        setConversationId(convoId);
      } catch (error) {
        const hasPreviouslyApplied =
          error?.responseBody?.type === 'record_not_unique';

        pushToastNotification({
          type: TOAST_NOTIFICATIONS_TYPES.ERROR,
          message: hasPreviouslyApplied
            ? ERROR_MESSAGE_ALREADY_APPLIED
            : ERROR_MESSAGE_GENERIC
        });

        if (!hasPreviouslyApplied) {
          window.bugsnagClient?.notify(error, {
            severity: 'error',
            metaData: { ...formState, ...profileIds }
          });
        }

        closeModal();
      }
    },
    mapFormStateToValidationSchema: _formState => {
      return {
        // New user account fields
        ...(!currentUser.isLoggedIn && {
          [FORM_ACCOUNT_EMAIL]:
            get(_formState, FORM_ACCOUNT_EMAIL) && CONSTRAINT_TYPES.EMAIL_VALID,
          [FORM_ACCOUNT_PASSWORD]:
            get(_formState, FORM_ACCOUNT_PASSWORD) && CONSTRAINT_TYPES.PASSWORD,
          [FORM_ACCOUNT_PHONE_NUMBER]:
            get(_formState, FORM_ACCOUNT_PHONE_NUMBER) &&
            CONSTRAINT_TYPES.TELEPHONE
        })
      };
    }
  });

  useEffect(() => {
    if (isModalOpen && !isEmpty(analytics)) {
      const originProps = removeEmptyValues({
        originView: analytics.origin_view,
        originViewFeature: analytics.origin_view_feature,
        originViewFeatureLocation: analytics.origin_view_feature_location
      });

      if (!isEmpty(originProps)) {
        setAnalyticsOrigin(originProps);
      }
    }
  }, [analytics]);

  useEffect(() => {
    if (!isFetching && isModalOpen) reset();
  }, [isFetching, isModalOpen]);

  if (!isModalOpen) return null;

  const isLoading = isFetching || contextValue.isSubmitting;

  const commonProps = { icon: null };

  orderedSteps = [
    {
      questionName: QUIZ_QUESTION_INTRO,
      Component: QuizIntroQuestion
    },
    {
      inputName: [FORM_ACCOUNT_FIRST_NAME, FORM_ACCOUNT_LAST_NAME],
      questionName: QUIZ_QUESTION_BUYER_NAME,
      Component: QuizNameQuestion,
      isValid:
        !isEmpty(get(formState, FORM_ACCOUNT_FIRST_NAME)) &&
        !isEmpty(get(formState, FORM_ACCOUNT_LAST_NAME)),
      otherProps: commonProps,
      shouldSkip: currentUser.isLoggedIn
    },
    {
      inputName: FORM_ACCOUNT_EMAIL,
      questionName: QUIZ_QUESTION_BUYER_EMAIL,
      Component: QuizEmailQuestion,
      isValid:
        !isValidating &&
        !isEmpty(get(formState, FORM_ACCOUNT_EMAIL)) &&
        isEmpty(formErrors[FORM_ACCOUNT_EMAIL]),
      otherProps: commonProps,
      shouldSkip: currentUser.isLoggedIn
    },
    {
      inputName: FORM_ACCOUNT_PHONE_NUMBER,
      questionName: QUIZ_QUESTION_BUYER_PHONE_NUMBER,
      Component: QuizPhoneQuestion,
      isValid:
        !isEmpty(get(formState, FORM_ACCOUNT_PHONE_NUMBER)) &&
        isEmpty(formErrors[FORM_ACCOUNT_PHONE_NUMBER]),
      otherProps: commonProps,
      shouldSkip: currentUser.isLoggedIn
    },
    {
      inputName: FORM_ACCOUNT_PASSWORD,
      inputType: 'password',
      questionName: QUIZ_QUESTION_BUYER_PASSWORD,
      Component: QuizPasswordQuestion,
      isValid:
        !isEmpty(get(formState, FORM_ACCOUNT_PASSWORD)) &&
        isEmpty(formErrors[FORM_ACCOUNT_PASSWORD]),
      otherProps: commonProps,
      shouldSkip: currentUser.isLoggedIn
    }
  ].filter(step => !step.shouldSkip);

  const lastStep =
    orderedSteps.length > 0 ? orderedSteps[orderedSteps.length - 1] : {};

  function handleNextClick(stepIndex) {
    const previousStep = orderedSteps[stepIndex - 1];

    if (previousStep.inputName) {
      trackStepAnswer({
        step: previousStep,
        formState,
        properties: profileIds
      });
    }
  }

  lastStep.otherProps = {
    ...lastStep.otherProps,
    handleNextClick: null,
    onSubmit: handleSubmission,
    submitBtnLabel: BUTTON_CONTINUE_LABEL,
    submitBtnClassName:
      lastStep.otherProps?.variant === WIDE_QUIZ_QUESTION ? null : 'w-100',
    autoNext: false
  };

  function handleLoginClick() {
    setSignupFormType(LOGIN);
  }

  function handlePasswordResetClick() {
    setSignupFormType(FORGOT_PASSWORD);
  }

  function handleLoginClose() {
    setSignupFormType(null);
  }

  function handleAuth({ data }) {
    const inquiries = get(data, 'data.breeder_profiles_initial_inquiries', []);

    const prevInquiry = inquiries.find(
      inquiry => inquiry.breeder_profile_id === breederProfile?.id
    );

    if (prevInquiry) {
      pushToastNotification({
        type: TOAST_NOTIFICATIONS_TYPES.ERROR,
        message: ERROR_MESSAGE_ALREADY_APPLIED
      });
    }

    handleLoginClose();
  }

  function handleStepShown(index) {
    const { questionName } = orderedSteps[index];
    trackStepView({ questionName, ...profileIds });
  }

  return (
    <OverlayModal
      className="bg-white"
      hideCloseButton
      closeButtonLabel="Exit"
      onClose={closeModal}
    >
      <LoadingWrapper className="bg-sand h-100" isLoading={isLoading}>
        {[LOGIN, FORGOT_PASSWORD].includes(signupFormType) && (
          <MiniOverlayModal
            className="UserAuthModal"
            onCloseClick={handleLoginClose}
          >
            {signupFormType === LOGIN ? (
              <UserAuthModalLoginForm
                loginOnly
                onPasswordResetClick={handlePasswordResetClick}
                onAuth={handleAuth}
              />
            ) : (
              <UserAuthModalForgotPasswordForm
                onLoginClick={handleLoginClick}
              />
            )}
          </MiniOverlayModal>
        )}
        {!conversationId && (
          <FormContext.Provider
            value={{
              ...contextValue,
              handleClose: closeModal,
              handleLoginClick
            }}
          >
            <FullPageQuiz
              className="bg-white z-max"
              formErrors={contextValue.formErrors}
              formState={contextValue.formState}
              handleFormFieldChange={handleFormFieldChange}
              isFormSubmitting={contextValue.isSubmitting}
              closeButtonLabel="Exit"
              orderedSteps={orderedSteps}
              onSaveAndExitClick={closeModal}
              onNextClick={handleNextClick}
              onSubmit={handleSubmission}
              isLoading={isLoading}
              onStepShown={handleStepShown}
              progressBarVariant={PROGRESS_BAR_VARIANTS.REGULAR}
              newDesignSystemStyles
            />
          </FormContext.Provider>
        )}
        {!currentUser.isLoggedIn && <IconSet />}
        {conversationId && <BuyerProfileQuiz />}
      </LoadingWrapper>
    </OverlayModal>
  );
}

export default function InitialInquiryQuiz(props) {
  return (
    <FeaturesReady timeout={500}>
      <Component
        litterId={props.litterId}
        puppyId={props.puppyId}
        breederProfileId={props.breederProfileId}
        inquirySource={props.inquirySource}
        onClose={props.onClose}
        analytics={props.analytics}
      />
    </FeaturesReady>
  );
}

InitialInquiryQuiz.propTypes = {
  litterId: PropTypes.number,
  puppyId: PropTypes.number,
  breederProfileId: PropTypes.number,
  inquirySource: PropTypes.oneOf(INITIAL_INQUIRY_SOURCE_VIEWS),
  onClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  analytics: PropTypes.object
};

InitialInquiryQuiz.defaultProps = {
  litterId: null,
  puppyId: null,
  breederProfileId: null,
  inquirySource: null,
  onClose: null,
  analytics: null
};

function Component(props) {
  return (
    <InitialInquiryContext.Provider
      value={{
        litterId: props.litterId,
        puppyId: props.puppyId,
        breederProfileId: props.breederProfileId,
        inquirySource: props.inquirySource,
        onClose: props.onClose,
        analytics: props.analytics
      }}
    >
      <InitialInquiryQuizContent />
    </InitialInquiryContext.Provider>
  );
}

Component.propTypes = {
  litterId: PropTypes.number,
  puppyId: PropTypes.number,
  breederProfileId: PropTypes.number,
  inquirySource: PropTypes.oneOf(INITIAL_INQUIRY_SOURCE_VIEWS),
  onClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  analytics: PropTypes.object
};

Component.defaultProps = {
  litterId: null,
  puppyId: null,
  breederProfileId: null,
  inquirySource: null,
  onClose: null,
  analytics: null
};
