export function userFollowsQueryDefinition(params) {
  return {
    url: `/api/user_follows.json`,
    params,
    defaultValue: {
      data: [],
      meta: {}
    }
  };
}

export function createUserFollowMutationDefinition() {
  return {
    url: '/api/user_follows.json',
    method: 'POST'
  };
}

export function removeUserFollowMutationDefinition(userFollowsId) {
  return {
    url: `/api/user_follows/${userFollowsId}.json`,
    method: 'DELETE'
  };
}
