import React from 'react';
import classnames from 'classnames';

import { get } from 'dpl/shared/utils/object';
import FullPageQuizQuestion from 'dpl/components/FullPageQuiz/FullPageQuizQuestion';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import InputCard from 'dpl/common/design_system/InputCard';

import {
  FORM_BUYER_OUTDOOR_ACCESS,
  FORM_SKIPS,
  ICON_CLASSNAMES,
  ICON_SIZE,
  OUTDOOR_ACCESS_OPTIONS,
  SELECTED_ICON_CLASSNAMES
} from '../utils/constants';
import useBuyerProfileQuiz from '../hooks/useBuyerProfileQuiz';

const OUTDOOR_ACCESS_ICON_PROPS = {
  [OUTDOOR_ACCESS_OPTIONS.LOCAL_PARK]: {
    name: 'fetch-tree'
  },
  [OUTDOOR_ACCESS_OPTIONS.FENCED_YARD]: {
    name: 'fetch-fence'
  },
  [OUTDOOR_ACCESS_OPTIONS.OPEN_YARD]: {
    name: 'fetch-rural'
  }
};

export default function QuizOutdoorQuestion(props) {
  const { formState, batchSetValue, setValue } = useFormContext();
  const { quizOptionsData } = useBuyerProfileQuiz();

  const outdoorAccess = get(formState, FORM_BUYER_OUTDOOR_ACCESS) || '';
  const skips = Array.from(get(formState, FORM_SKIPS, []));

  function handleFieldChange(e) {
    const idx = skips.indexOf(FORM_BUYER_OUTDOOR_ACCESS);
    if (idx > -1) {
      skips.splice(idx, 1);
    }

    batchSetValue({
      [FORM_BUYER_OUTDOOR_ACCESS]: e.target.value,
      [FORM_SKIPS]: skips
    });
  }

  return (
    <div className="BuyerProfileQuizOutdoorQuestion">
      <FullPageQuizQuestion
        title="What’s your outdoor access like?"
        subtitle="Select one option."
        showSkipBtn
        {...props}
        handleSkipClick={() => {
          setValue(FORM_SKIPS, new Set([...skips, FORM_BUYER_OUTDOOR_ACCESS]));
          // eslint-disable-next-line react/prop-types
          props.handleSkipClick();
        }}
      >
        <ul className="flex flex-wrap justify-center tl">
          {quizOptionsData.outdoor_access.map(option => (
            <li key={option.value}>
              <InputCard
                className={classnames(
                  'bg-white tertiary ba mh1 mv1',
                  outdoorAccess === option.value
                    ? 'b--midnight midnight'
                    : 'b--default'
                )}
                icon={{
                  ...OUTDOOR_ACCESS_ICON_PROPS[option.value],
                  size: ICON_SIZE,
                  className:
                    outdoorAccess === option.value
                      ? SELECTED_ICON_CLASSNAMES
                      : ICON_CLASSNAMES
                }}
                type="radio"
                minHeight="110px"
                width="156px"
                inputProps={{
                  name: FORM_BUYER_OUTDOOR_ACCESS,
                  value: option.value,
                  checked: outdoorAccess === option.value,
                  onChange: e => {
                    if (e.target.checked) {
                      handleFieldChange(e);
                    }
                  }
                }}
              >
                <span className="font-16">{option.label}</span>
              </InputCard>
            </li>
          ))}
        </ul>
      </FullPageQuizQuestion>
    </div>
  );
}
