import { useMemo } from 'react';

import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import useResourceMutation from 'dpl/shared/fetching/hooks/useResourceMutation';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';

import {
  userFollowsQueryDefinition,
  createUserFollowMutationDefinition,
  removeUserFollowMutationDefinition
} from '../utils/resourceDefinitions';

export default function useUserFollowsData(followableId, followableType) {
  const { isBuyer } = useCurrentUser();

  const userFollowsData = useResourceQuery({
    ...userFollowsQueryDefinition({ ...followableType }),
    enabled: Boolean(isBuyer)
  });

  const { mutateAsync: createUserFollow, isLoading: isCreating } =
    useResourceMutation(createUserFollowMutationDefinition());

  const hasUserFollow = useMemo(() => {
    return userFollowsData.value.data.find(
      f => f.followable_id === followableId
    );
  }, [userFollowsData]);

  const { mutateAsync: removeUserFollow, isLoading: isRemoving } =
    useResourceMutation(
      removeUserFollowMutationDefinition(hasUserFollow && hasUserFollow.id)
    );

  return {
    userFollowsData,
    createUserFollow,
    ...(hasUserFollow && { removeUserFollow }),
    ...(followableId && { hasUserFollow }),
    isLoading: userFollowsData.isLoading,
    isUpdating: isCreating || isRemoving
  };
}
