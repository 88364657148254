export function getEditBuyerProfileQuizQueryDefinition({
  buyerProfileId,
  conversationId
}) {
  return {
    url: `/api/initial_inquiries/buyer_profiles/${buyerProfileId}/edit.json`,
    method: 'GET',
    params: {
      conversation_id: conversationId
    },
    defaultValue: {
      buyer_profile: {
        home_type: '',
        outdoor_access: '',
        household_tags: [],
        pet_experience_tags: [],
        address_attributes: {
          postal_code: ''
        },
        user_attributes: {
          profile_photo_attributes: null,
          id: null
        }
      },
      options: {
        home_types: [],
        household_tags: [],
        outdoor_access: [],
        pet_experience_tags: []
      }
    }
  };
}

export function updateBuyerProfileQuizMutationDefinition({
  buyerProfileId,
  conversationId
}) {
  return {
    url: `/api/initial_inquiries/buyer_profiles/${buyerProfileId}.json`,
    method: 'PATCH',
    params: {
      conversation_id: conversationId
    },
    defaultValue: {
      buyer_profile: {
        home_type: '',
        outdoor_access: '',
        household_tags: [],
        pet_experience_tags: [],
        address_attributes: {
          postal_code: ''
        },
        user_attributes: {
          profile_photo_attributes: null,
          id: null
        }
      },
      conversation_message: {
        conversation_id: null,
        message_text: ''
      }
    }
  };
}
