import React from 'react';

import FullPageQuizQuestion from 'dpl/components/FullPageQuiz/FullPageQuizQuestion';
import FullPageQuizTextInput from 'dpl/components/FullPageQuiz/FullPageQuizTextInput';
import ErrorWrapper from 'dpl/form/components/ErrorWrapper';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import { get } from 'dpl/shared/utils/object';
import { CONSTRAINT_MESSAGES } from 'dpl/shared/validations/constraints';
import EmailUnavailableErrorMessage from 'dpl/shared/validations/EmailUnavailableErrorMessage';

import { FORM_ACCOUNT_EMAIL } from '../utils/constants';

export default function QuizEmailQuestion(props) {
  const { formState, formErrors, handleFormFieldChange, handleLoginClick } =
    useFormContext();

  const email = get(formState, FORM_ACCOUNT_EMAIL, '');

  const errors = formErrors[FORM_ACCOUNT_EMAIL] || [];
  const emailUnavailable = errors[0] === CONSTRAINT_MESSAGES.EMAIL_UNAVAILABLE;

  if (emailUnavailable) {
    errors[0] = (
      <EmailUnavailableErrorMessage onLoginClick={handleLoginClick} />
    );
  }

  return (
    <FullPageQuizQuestion
      className="InitialInquiryQuizEmailQuestion"
      title="What’s your email?"
      subtitle="To access your account."
      newDesignSystemStyles
      {...props}
    >
      <ErrorWrapper
        errors={errors}
        isShown={emailUnavailable}
        newDesignSystemStyles
      >
        <FullPageQuizTextInput
          type="email"
          className="mb1"
          id={FORM_ACCOUNT_EMAIL}
          name={FORM_ACCOUNT_EMAIL}
          autoCapitalize="none"
          placeholder="Email"
          value={email}
          onChange={handleFormFieldChange}
          labelText="Email"
          newDesignSystemStyles
          hasErrors={errors.length > 0 && email.length > 0}
        />
      </ErrorWrapper>
    </FullPageQuizQuestion>
  );
}
