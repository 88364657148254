import React from 'react';

import DogPlaceholderSvg from 'dpl/common/components/DogPlaceholderSvg';
import FullPageQuizQuestion from 'dpl/components/FullPageQuiz/FullPageQuizQuestion';
import SmartImage from 'dpl/components/SmartImage';
import UserAvatar from 'dpl/path_to_payment/components/UserAvatar';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import { GENDERS } from 'dpl/shared/constants/breeder_app';

import useInitialInquiry from '../hooks/useInitialInquiry';

export default function QuizIntroQuestion(props) {
  const { breederProfile, currentPuppy } = useInitialInquiry();
  const { handleLoginClick } = useFormContext();
  const currentUser = useCurrentUser();

  const {
    user: { first_name: breederFirstName }
  } = breederProfile;

  const {
    name: puppyName,
    profile_photo_url: puppyProfilePhotoUrl,
    gender
  } = currentPuppy;

  return (
    <>
      <FullPageQuizQuestion
        className="InitialInquiryQuizIntroQuestion"
        newDesignSystemStyles
        {...props}
      >
        <div className="mb8 flex justify-center">
          <UserAvatar
            size="lg"
            user={{ ...breederProfile, id: breederProfile.user.id }}
          />
          {puppyProfilePhotoUrl ? (
            <SmartImage
              Tag="div"
              width="64px"
              height="64px"
              lazy
              className="OverlappingImage bg-center cover h-100 w-100 br-100"
              loadingClass="bg-light-gray br-100"
              src={puppyProfilePhotoUrl}
            />
          ) : (
            <DogPlaceholderSvg
              width="64px"
              height="64px"
              puppy
              className="OverlappingImage bg-light-gray br-100"
            />
          )}
        </div>
        <h3 className="f4 f6-md lh-title mb4">
          Learn more about {puppyName} from{' '}
          {gender === GENDERS.FEMALE ? 'her' : 'his'} breeder,{' '}
          {breederFirstName}
        </h3>
        <p className="secondary font-16-mobile mb10">
          Before you chat, we’ll need a little info so {breederFirstName} can
          get to know you.
        </p>
      </FullPageQuizQuestion>
      {!currentUser.isLoggedIn && (
        <p className="secondary tc f2 nt3">
          Already have an account?{' '}
          <button
            className="royal-blue link--primary"
            onClick={handleLoginClick}
            type="button"
          >
            Log in
          </button>{' '}
        </p>
      )}
    </>
  );
}
