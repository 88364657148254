import React from 'react';

import { get } from 'dpl/shared/utils/object';
import ErrorWrapper from 'dpl/form/components/ErrorWrapper';
import FullPageQuizTextInput from 'dpl/components/FullPageQuiz/FullPageQuizTextInput';
import FullPageQuizQuestion from 'dpl/components/FullPageQuiz/FullPageQuizQuestion';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';

import { FORM_BUYER_POSTAL_CODE, FORM_SKIPS } from '../utils/constants';

export default function QuizZipcodeQuestion(props) {
  const { formState, formErrors, batchSetValue, setValue } = useFormContext();

  const zipcode = get(formState, FORM_BUYER_POSTAL_CODE) || '';
  const errors = formErrors[FORM_BUYER_POSTAL_CODE] || [];
  const skips = Array.from(get(formState, FORM_SKIPS, []));

  function handleFieldChange(e) {
    const idx = skips.indexOf(FORM_BUYER_POSTAL_CODE);
    if (idx > -1) {
      skips.splice(idx, 1);
    }

    batchSetValue({
      [FORM_BUYER_POSTAL_CODE]: e.target.value,
      [FORM_SKIPS]: skips
    });
  }

  return (
    <div className="BuyerProfileQuizZipcodeQuestion">
      <FullPageQuizQuestion
        title="Where are you located?"
        subtitle="Breeders like to get a sense of distance."
        showSkipBtn
        {...props}
        // doesnt only use the default value from props
        handleSkipClick={() => {
          setValue(FORM_SKIPS, new Set([...skips, FORM_BUYER_POSTAL_CODE]));
          // eslint-disable-next-line react/prop-types
          props.handleSkipClick();
        }}
      >
        <ErrorWrapper errors={errors} newDesignSystemStyles>
          <FullPageQuizTextInput
            className="mb1"
            id={FORM_BUYER_POSTAL_CODE}
            name={FORM_BUYER_POSTAL_CODE}
            onChange={e => handleFieldChange(e)}
            type="text"
            value={zipcode}
            placeholder="Zip code"
            labelText="Zip code"
            hasErrors={errors.length > 0 && zipcode.length > 0}
          />
        </ErrorWrapper>
      </FullPageQuizQuestion>
    </div>
  );
}
