export const INITIAL_INQUIRIES_FIELD_NAME =
  'value.data.breeder_profiles_initial_inquiries';

export const FORM_ACCOUNT_FIRST_NAME = 'account.first_name';
export const FORM_ACCOUNT_LAST_NAME = 'account.last_name';
export const FORM_ACCOUNT_PHONE_NUMBER = 'account.phone_number';
export const FORM_ACCOUNT_EMAIL = 'account.email';
export const FORM_ACCOUNT_PASSWORD = 'account.password';

export const FORM_BUYER_HOME_TYPE = 'buyer_profile.home_type';
export const FORM_BUYER_OUTDOOR_ACCESS = 'buyer_profile.outdoor_access';
export const FORM_BUYER_LIFESTYLE = 'conversation_message.message_text';
export const FORM_BUYER_HOUSEHOLD = 'buyer_profile.household_tags';
export const FORM_BUYER_PET_EXPERIENCE = 'buyer_profile.pet_experience_tags';
export const FORM_BUYER_POSTAL_CODE =
  'buyer_profile.address_attributes.postal_code';
export const FORM_BUYER_PROFILE_PHOTO =
  'buyer_profile.user_attributes.profile_photo_attributes';
export const FORM_SKIPS = 'skips';
export const FORM_SUBMITTING = 'submitting';

export const FORM_MESSAGE_TEXT_NAME = 'conversation_message.message_text';

export const FORM_BREEDER_PROFILE_ID_NAME = 'with.breeder_profile_id';

export const BUTTON_SEND_REQUEST_LABEL = 'Send request';
export const BUTTON_CONTINUE_LABEL = 'Continue';

export const ERROR_MESSAGE_GENERIC = 'An error has occurred. Please try again.';
export const ERROR_MESSAGE_ALREADY_APPLIED =
  'Youʼve already applied to this breeder.';

export const QUIZ_QUESTION_INTRO = 'initial-inquiry-intro';
export const QUIZ_QUESTION_MESSAGE = 'message-text';
export const QUIZ_QUESTION_BUYER_NAME = 'buyer-name';
export const QUIZ_QUESTION_BUYER_PHONE_NUMBER = 'buyer-phone';
export const QUIZ_QUESTION_BUYER_EMAIL = 'buyer-email';
export const QUIZ_QUESTION_BUYER_PASSWORD = 'buyer-password';
export const QUIZ_QUESTION_BUYER_POSTAL = 'buyer-postal-code';
export const QUIZ_QUESTION_BUYER_HOME = 'buyer-home';
export const QUIZ_QUESTION_BUYER_OUTDOOR = 'buyer-outdoor';
export const QUIZ_QUESTION_BUYER_LIFESTYLE = 'buyer-lifestyle';
export const QUIZ_QUESTION_BUYER_HOUSEHOLD = 'buyer-household';
export const QUIZ_QUESTION_BUYER_PET_EXPERIENCE = 'buyer-pet-experience';
export const QUIZ_QUESTION_BUYER_PROFILE_PHOTO = 'buyer-profile-photo';

export const HOME_TYPES = {
  HOUSE: 'house',
  APARTMENT: 'apartment',
  TOWNHOUSE: 'townhouse',
  FARM: 'farm',
  RV: 'rv'
};

export const OUTDOOR_ACCESS_OPTIONS = {
  LOCAL_PARK: 'park',
  FENCED_YARD: 'fenced_yard',
  OPEN_YARD: 'open_yard'
};

export const ICON_CLASSNAMES = 'bg-passive tertiary ph2 pv2';
export const SELECTED_ICON_CLASSNAMES = 'bg-cloud midnight ph2 pv2';
export const ICON_SIZE = '20px';
