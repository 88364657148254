import React from 'react';

import FullPageQuizQuestion from 'dpl/components/FullPageQuiz/FullPageQuizQuestion';
import TelephoneInput from 'dpl/form/components/TelephoneInput';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import { get } from 'dpl/shared/utils/object';

import { FORM_ACCOUNT_PHONE_NUMBER } from '../utils/constants';

export default function QuizPhoneQuestion(props) {
  const { formState } = useFormContext();

  const phoneNumber = get(formState, FORM_ACCOUNT_PHONE_NUMBER, '');

  return (
    <div className="InitialInquiryQuizPhoneQuestion FullPageQuizQuestion center">
      <FullPageQuizQuestion
        title="What’s your phone number?"
        subtitle="Breeders often like to speak by phone."
        icon="phone"
        newDesignSystemStyles
        {...props}
      >
        <TelephoneInput
          inputClassName="w-100 input-reset font-16 secondary pv3 pv4-md ph4 br2 ba b--moon-gray mb1"
          id={FORM_ACCOUNT_PHONE_NUMBER}
          name={FORM_ACCOUNT_PHONE_NUMBER}
          value={phoneNumber}
          placeholder="000-000-0000"
          fullWidth
          label="Phone"
          newDesignSystemStyles
        />
      </FullPageQuizQuestion>
      <p className="f1 tertiary tc ph5 nt3 mb3">
        By clicking &lsquo;Continue&rsquo;, you consent to receive service and
        offer-related text messages from Good Dog at the number provided, like
        when a breeder sends you a message. Messages may be sent using automated
        systems for the selection and dialing of numbers. Consent is not a
        condition of any purchase. Reply STOP to unsubscribe any time. Message
        and data rates may apply.
      </p>
    </div>
  );
}
