import React from 'react';

export default function IconSet() {
  return (
    <svg className="dn" xmlns="http://www.w3.org/2000/svg">
      <symbol id="icon-fullpagequiz-lock" viewBox="0 0 136 136">
        <g transform="translate(29 15)" fill="none" fillRule="evenodd">
          <circle
            stroke="#2E2EE5"
            strokeWidth="3.5"
            fill="#EAEAFB"
            cx="39.1"
            cy="66.3"
            r="39.1"
          />
          <circle fill="#2E2EE5" cx="39.1" cy="35.7" r="1.7" />
          <circle fill="#2E2EE5" cx="39.1" cy="96.9" r="1.7" />
          <circle fill="#2E2EE5" cx="69.7" cy="66.3" r="1.7" />
          <circle fill="#2E2EE5" cx="8.5" cy="66.3" r="1.7" />
          <circle fill="#2E2EE5" cx="23.8" cy="39.8" r="1.7" />
          <circle fill="#2E2EE5" cx="54.4" cy="92.8" r="1.7" />
          <circle fill="#2E2EE5" cx="65.6" cy="51" r="1.7" />
          <circle fill="#2E2EE5" cx="12.6" cy="81.6" r="1.7" />
          <circle fill="#2E2EE5" cx="12.6" cy="51" r="1.7" />
          <circle fill="#2E2EE5" cx="65.6" cy="81.6" r="1.7" />
          <circle fill="#2E2EE5" cx="54.4" cy="39.8" r="1.7" />
          <circle fill="#2E2EE5" cx="23.8" cy="92.8" r="1.7" />
          <circle fill="#2E2EE5" cx="31.18" cy="36.742" r="1.7" />
          <circle fill="#2E2EE5" cx="47.02" cy="95.858" r="1.7" />
          <circle fill="#2E2EE5" cx="68.658" cy="58.38" r="1.7" />
          <circle fill="#2E2EE5" cx="9.542" cy="74.22" r="1.7" />
          <circle fill="#2E2EE5" cx="17.462" cy="44.662" r="1.7" />
          <circle fill="#2E2EE5" cx="60.738" cy="87.938" r="1.7" />
          <circle fill="#2E2EE5" cx="60.738" cy="44.662" r="1.7" />
          <circle fill="#2E2EE5" cx="17.462" cy="87.938" r="1.7" />
          <circle fill="#2E2EE5" cx="9.542" cy="58.38" r="1.7" />
          <circle fill="#2E2EE5" cx="68.658" cy="74.22" r="1.7" />
          <circle fill="#2E2EE5" cx="47.02" cy="36.742" r="1.7" />
          <circle fill="#2E2EE5" cx="31.18" cy="95.858" r="1.7" />
          <path
            d="M13.6 37.4V25.5C13.6 11.417 25.017 0 39.1 0h0c14.083 0 25.5 11.417 25.5 25.5v11.9"
            stroke="#2E2EE5"
            strokeWidth="3.5"
          />
          <path
            d="M45.9 62.9a6.8 6.8 0 00-13.6 0c0 2.51 1.375 4.678 3.4 5.857V76.5a3.4 3.4 0 003.4 3.4 3.4 3.4 0 003.4-3.4v-7.743c2.025-1.179 3.4-3.346 3.4-5.857z"
            stroke="#2E2EE5"
            strokeWidth="3.5"
            fill="#FFF"
          />
        </g>
      </symbol>
      <symbol id="icon-fullpagequiz-mailbox" viewBox="0 0 136 136">
        <g transform="translate(9 23)" fill="none" fillRule="evenodd">
          <circle fill="#2E2EE5" cx="108.8" cy="69.7" r="1.7" />
          <circle fill="#2E2EE5" cx="102" cy="69.7" r="1.7" />
          <circle fill="#2E2EE5" cx="95.2" cy="69.7" r="1.7" />
          <circle fill="#2E2EE5" cx="88.4" cy="69.7" r="1.7" />
          <circle fill="#2E2EE5" cx="81.6" cy="69.7" r="1.7" />
          <circle fill="#2E2EE5" cx="74.8" cy="69.7" r="1.7" />
          <circle fill="#2E2EE5" cx="68" cy="69.7" r="1.7" />
          <circle fill="#2E2EE5" cx="61.2" cy="69.7" r="1.7" />
          <circle fill="#2E2EE5" cx="54.4" cy="69.7" r="1.7" />
          <path
            d="M69.7 5.1h-34c-11.266 0-20.4 9.134-20.4 20.4v44.2H3.4A3.4 3.4 0 000 73.1a3.4 3.4 0 003.4 3.4h110.5a3.4 3.4 0 003.4-3.4 3.4 3.4 0 00-3.4-3.4V25.5c0-11.266-9.134-20.4-20.4-20.4"
            fill="#EAEAFB"
          />
          <path
            d="M69.7 5.1h-34c-11.266 0-20.4 9.134-20.4 20.4v44.2H3.4A3.4 3.4 0 000 73.1a3.4 3.4 0 003.4 3.4h110.5a3.4 3.4 0 003.4-3.4 3.4 3.4 0 00-3.4-3.4V25.5c0-11.266-9.134-20.4-20.4-20.4"
            stroke="#2E2EE5"
            strokeWidth="3.5"
            strokeLinejoin="round"
          />
          <path
            d="M34 5.1h0c11.266 0 20.4 9.134 20.4 20.4v44.2"
            stroke="#2E2EE5"
            strokeWidth="3.5"
            strokeLinejoin="round"
          />
          <path
            stroke="#2E2EE5"
            strokeWidth="3.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M54.4 69.7H15.3M74.8 8.5h13.6V0H74.8v27.2"
          />
          <path
            stroke="#2E2EE5"
            strokeWidth="3.5"
            fill="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M74.8 8.5h13.6V0H74.8v27.2"
          />
          <path
            stroke="#2E2EE5"
            strokeWidth="3.5"
            strokeLinejoin="round"
            d="M73.1 98.6V76.5M62.9 98.6V76.5"
          />
          <circle fill="#2E2EE5" cx="74.8" cy="27.2" r="3.4" />
          <path
            stroke="#2E2EE5"
            strokeWidth="3.5"
            fill="#FFF"
            strokeLinejoin="round"
            d="M25.5 69.7V27.2h28.9v42.5z"
          />
          <path
            stroke="#2E2EE5"
            strokeWidth="3.5"
            strokeLinejoin="round"
            d="M25.5 69.7V27.2h28.9m-28.521 5.792L54.4 47.6"
          />
        </g>
      </symbol>
      <symbol id="icon-fullpagequiz-phone" viewBox="0 0 136 136">
        <path
          d="M31.0088 74.9912C51.1198 95.1022 75.1 104 75.1 104C75.1 104 93.3563 99.5851 97.3207 95.6207L101.231 91.7107C104.925 88.0166 104.925 82.0292 101.231 78.3351L88.8785 65.9829C85.5584 62.6628 80.1779 62.6628 76.8578 65.9829L62.8056 80.0351C62.8056 80.0351 50.4602 74.3775 41.0422 64.9595C31.6242 55.5415 25.9666 43.1961 25.9666 43.1961L40.0188 29.1439C43.3389 25.8238 43.3389 20.4433 40.0188 17.1232L27.6666 4.771C23.9725 1.0769 17.9851 1.0769 14.291 4.771L10.381 8.681C6.4149 12.6437 2 30.9 2 30.9C2 30.9 10.8978 54.8802 31.0088 74.9912Z"
          fill="#EAEAFB"
          stroke="#2E2EE5"
          strokeWidth="3.4"
          strokeMiterlimit="10"
        />
        <path
          d="M15.5999 8.79999C16.5388 8.79999 17.2999 8.03888 17.2999 7.09999C17.2999 6.16111 16.5388 5.39999 15.5999 5.39999C14.661 5.39999 13.8999 6.16111 13.8999 7.09999C13.8999 8.03888 14.661 8.79999 15.5999 8.79999Z"
          fill="#2E2EE5"
        />
        <path
          d="M20.7 13.9C21.6389 13.9 22.4 13.1389 22.4 12.2C22.4 11.2611 21.6389 10.5 20.7 10.5C19.7611 10.5 19 11.2611 19 12.2C19 13.1389 19.7611 13.9 20.7 13.9Z"
          fill="#2E2EE5"
        />
        <path
          d="M25.8001 19C26.739 19 27.5001 18.2389 27.5001 17.3C27.5001 16.3611 26.739 15.6 25.8001 15.6C24.8612 15.6 24.1001 16.3611 24.1001 17.3C24.1001 18.2389 24.8612 19 25.8001 19Z"
          fill="#2E2EE5"
        />
        <path
          d="M30.9002 24.1C31.8391 24.1 32.6002 23.3389 32.6002 22.4C32.6002 21.4611 31.8391 20.7 30.9002 20.7C29.9613 20.7 29.2002 21.4611 29.2002 22.4C29.2002 23.3389 29.9613 24.1 30.9002 24.1Z"
          fill="#2E2EE5"
        />
        <path
          d="M35.9998 29.2C36.9387 29.2 37.6998 28.4389 37.6998 27.5C37.6998 26.5611 36.9387 25.8 35.9998 25.8C35.0609 25.8 34.2998 26.5611 34.2998 27.5C34.2998 28.4389 35.0609 29.2 35.9998 29.2Z"
          fill="#2E2EE5"
        />
        <path
          d="M98.9002 92.1C99.8391 92.1 100.6 91.3389 100.6 90.4C100.6 89.4611 99.8391 88.7 98.9002 88.7C97.9613 88.7 97.2002 89.4611 97.2002 90.4C97.2002 91.3389 97.9613 92.1 98.9002 92.1Z"
          fill="#2E2EE5"
        />
        <path
          d="M93.8001 87C94.739 87 95.5001 86.2389 95.5001 85.3C95.5001 84.3611 94.739 83.6 93.8001 83.6C92.8612 83.6 92.1001 84.3611 92.1001 85.3C92.1001 86.2389 92.8612 87 93.8001 87Z"
          fill="#2E2EE5"
        />
        <path
          d="M88.7 81.9C89.6389 81.9 90.4 81.1389 90.4 80.2C90.4 79.2611 89.6389 78.5 88.7 78.5C87.7611 78.5 87 79.2611 87 80.2C87 81.1389 87.7611 81.9 88.7 81.9Z"
          fill="#2E2EE5"
        />
        <path
          d="M83.5999 76.8C84.5388 76.8 85.2999 76.0389 85.2999 75.1C85.2999 74.1611 84.5388 73.4 83.5999 73.4C82.661 73.4 81.8999 74.1611 81.8999 75.1C81.8999 76.0389 82.661 76.8 83.5999 76.8Z"
          fill="#2E2EE5"
        />
        <path
          d="M78.4998 71.7C79.4387 71.7 80.1998 70.9389 80.1998 70C80.1998 69.0611 79.4387 68.3 78.4998 68.3C77.5609 68.3 76.7998 69.0611 76.7998 70C76.7998 70.9389 77.5609 71.7 78.4998 71.7Z"
          fill="#2E2EE5"
        />
      </symbol>
    </svg>
  );
}
