import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import DogPlaceholderSvg from 'dpl/common/components/DogPlaceholderSvg';
import HealthTierBadge from 'dpl/common/components/HealthTierBadge';
import Icon from 'dpl/common/components/Icon';
import BreederProfileFamilyOverlay, {
  BreederProfileFamilyOverlayDetailsSectionItems
} from 'dpl/components/BreederProfile/Show/BreederProfileFamilyOverlay';
import LoadingWrapper from 'dpl/components/LoadingWrapper';
import MediaCarousel from 'dpl/components/MediaCarousel';
import OverlayNavigationArrow from 'dpl/components/OverlayNavigationArrow';
import { ANALYTICS_VIEWS } from 'dpl/constants/analytics';
import { ANALYTICS } from 'dpl/constants/query_params';
import ModalApplyButton from 'dpl/dog_modals/components/ModalApplyButton';
import { BREEDING_STATUSES } from 'dpl/shared/constants/shared';
import { BREED_HEALTH_TESTING_GUIDE_PATH } from 'dpl/shared/constants/urls';
import { capitalize, isMobileUA } from 'dpl/shared/utils';

import useParentModal from '../hooks/useParentModal';

export const imageDimensions = isMobileUA()
  ? {
      width: `${document.body.getBoundingClientRect().width}px`,
      height: '267px'
    }
  : { width: '800px', height: '533px' };

export default function ParentModal(props) {
  const {
    isOpen,
    onClose,
    onDogClick,
    isLoading,
    dog,
    previousDog,
    nextDog,
    dogImages
  } = useParentModal({
    dogId: props.dogId,
    onDogClick: props.onDogClick,
    onClose: props.onClose
  });

  const { pageName } = useSelector(({ server }) => server);
  const { queryParams } = useSelector(state => state);
  const showNavigationArrows = pageName === 'breeder_profiles_show';

  const details = [
    ['Registered Name', dog.registered_name],
    ['Breed', dog.breed_name],
    ['Gender', dog.gender],
    ['Age', dog.age],
    ['Generation', dog.generation],
    ['Color', dog.color],
    ['Size', dog.formatted_weight],
    ['Coat type', dog.coat],
    ['Awards', dog.formatted_awards],
    ['Sire', dog.sire],
    ['Dam', dog.dam]
  ];

  const dogHealthTestResults = useMemo(
    () =>
      (dog.health_test_results || []).filter(
        htr => (htr.health_test_result_documents_attributes || []).length !== 0
      ),
    [dog]
  );

  const analytics = {
    ...queryParams[ANALYTICS],
    breeder_preferred_status:
      dog.breeder_profile?.preferred_qualification?.status,
    view: ANALYTICS_VIEWS.SIRE_DAM_MODAL
  };

  if (!isOpen) {
    return null;
  }

  return (
    <BreederProfileFamilyOverlay onClose={onClose}>
      <div className="BreederProfileParentOverlay">
        <LoadingWrapper isLoading={isLoading} className="transform-center-all">
          <div className="pl4 pr1 flex align-items-center justify-between">
            <h6 className="pv4 flex truncate">
              <p className="truncate">{dog.name}</p>
              <p className="pr2">&nbsp;-&nbsp;{capitalize(dog.gender || '')}</p>
            </h6>
            {dog.breeding_status !== BREEDING_STATUSES.ACTIVE ? (
              <span className="ba br1 f2 fw-normal silver pv1 ph2 mr3">
                {dog.breeding_status}
              </span>
            ) : (
              <ModalApplyButton
                className="button button--primary pv3 pv4-lg ph4 flex align-items-center bw0 br3 fw-medium"
                analytics={analytics}
                breederProfileId={dog.breeder_profile.id}
                breederProfileUrl={dog.breeder_profile.profile_path}
                customCopy="Request info"
              />
            )}
          </div>

          <div className="bg-black relative">
            {showNavigationArrows && previousDog && (
              <OverlayNavigationArrow
                direction="left"
                onClick={() => onDogClick(previousDog.id)}
              />
            )}

            {dogImages.length > 0 ? (
              <MediaCarousel
                files={dogImages}
                showArrowsOnHover
                dimensions={imageDimensions}
                smartImageProps={{
                  Tag: 'div',
                  className: 'contain bg-center mw-100 min-w-100',
                  loadingClass: 'bg-black'
                }}
              />
            ) : (
              <DogPlaceholderSvg
                className="bg-white bb bw1 b--near-white"
                {...imageDimensions}
              />
            )}

            {showNavigationArrows && nextDog && (
              <OverlayNavigationArrow
                direction="right"
                onClick={() => onDogClick(nextDog.id)}
              />
            )}
          </div>
          <div className="ph2 ph7-sm pv6">
            <div className="row">
              <div className="col-md-7 mb6">
                <p className="subheader mb3">About {dog.name}</p>
                {dog.description && <p className="mb5">{dog.description}</p>}
                <BreederProfileFamilyOverlayDetailsSectionItems
                  details={details}
                />
              </div>
              {dogHealthTestResults.length > 0 &&
                dog.health_test_results_public && (
                  <div className="col-md-5 mb6 f2">
                    <p className="subheader mb4">Health Tests</p>
                    {dog.health_tier_title && (
                      <>
                        <div className="flex items-center justify-center purple ba b--purple pv6 mb4">
                          <HealthTierBadge
                            healthTierTitle={dog.health_tier_title}
                          />
                        </div>
                        <p className="mb4">
                          {dog.name} meets Good Dog&apos;s health testing
                          requirements for the {dog.health_tier_title}{' '}
                          level.&nbsp;
                          <a
                            className="underline"
                            target="_blank"
                            rel="noreferrer noopener"
                            href={`/breeds/${dog.breed_slug}${BREED_HEALTH_TESTING_GUIDE_PATH}`}
                          >
                            Learn more
                          </a>
                          .
                        </p>
                      </>
                    )}
                    {dogHealthTestResults.map(htr => (
                      <div className="mb5" key={htr.id}>
                        <p className="fw-medium mb2">{htr.health_test_name}</p>
                        {htr.description && (
                          <p className="mb2">{htr.description}</p>
                        )}
                        {htr.health_test_result_documents_attributes?.map(
                          doc => (
                            <a
                              key={doc.id}
                              href={doc.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="link--primary flex items-center"
                            >
                              <span className="underline">
                                View {doc.is_file ? 'documentation' : 'results'}
                              </span>
                              <Icon
                                name="external"
                                width="20px"
                                height="20px"
                                className="ml2 black"
                              />
                            </a>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                )}
              {(dogHealthTestResults.length === 0 ||
                !dog.health_test_results_public) && (
                <div className="col-md-5 mb6">
                  <p className="subheader mb3">Health Tests</p>
                  <p className="f2">
                    The breeder reported meeting Good Dog&apos;s health testing
                    requirements but has not shared documentation. Contact the
                    breeder to discuss health testing performed for {dog.name}
                    .&nbsp;
                    <a
                      className="underline"
                      target="_blank"
                      rel="noreferrer noopener"
                      href={`/breeds/${dog.breed_slug}${BREED_HEALTH_TESTING_GUIDE_PATH}`}
                    >
                      Learn more
                    </a>
                    .
                  </p>
                </div>
              )}
            </div>
          </div>
        </LoadingWrapper>
      </div>
    </BreederProfileFamilyOverlay>
  );
}

ParentModal.propTypes = {
  dogId: PropTypes.number,
  onDogClick: PropTypes.func,
  onClose: PropTypes.func
};

ParentModal.defaultProps = {
  dogId: null,
  onDogClick: null,
  onClose: null
};
