import React, { useEffect, useState } from 'react';
import { isEmpty } from 'validate.js';

import FullPageQuiz, {
  PROGRESS_BAR_VARIANTS
} from 'dpl/components/FullPageQuiz';
import { FULL_PAGE_QUIZ_QUESTION_VARIANTS } from 'dpl/components/FullPageQuiz/FullPageQuizQuestion';
import LoadingWrapper from 'dpl/components/LoadingWrapper';
import OverlayModal from 'dpl/components/OverlayModal';
import useForm from 'dpl/shared/form/hooks/useForm';
import FormContext from 'dpl/shared/form/utils/context';
import { remove, get } from 'dpl/shared/utils/object';
import { sendAnalyticsEvent } from 'dpl/util/analytics';
import { ANALYTICS_EVENTS } from 'dpl/constants/analytics';
import {
  BREEDER_ACTIVE_MESSAGES_PATH,
  BUYER_ACTIVE_MESSAGES_PATH
} from 'dpl/shared/constants/urls';
import useResourceMutation from 'dpl/shared/fetching/hooks/useResourceMutation';
import { CONSTRAINT_TYPES } from 'dpl/shared/validations/constraints';
import { postReactNativeCloseWebViewMessage } from 'dpl/native_app/utils';

import {
  BUTTON_CONTINUE_LABEL,
  FORM_BUYER_POSTAL_CODE,
  QUIZ_QUESTION_BUYER_POSTAL,
  FORM_BUYER_HOME_TYPE,
  QUIZ_QUESTION_BUYER_HOME,
  FORM_BUYER_OUTDOOR_ACCESS,
  QUIZ_QUESTION_BUYER_OUTDOOR,
  FORM_BUYER_LIFESTYLE,
  QUIZ_QUESTION_BUYER_LIFESTYLE,
  FORM_BUYER_PROFILE_PHOTO,
  QUIZ_QUESTION_BUYER_PROFILE_PHOTO,
  FORM_BUYER_HOUSEHOLD,
  QUIZ_QUESTION_BUYER_HOUSEHOLD,
  FORM_BUYER_PET_EXPERIENCE,
  QUIZ_QUESTION_BUYER_PET_EXPERIENCE,
  FORM_SUBMITTING,
  FORM_SKIPS
} from '../utils/constants';
import useBuyerProfileQuiz from '../hooks/useBuyerProfileQuiz';
import { trackStepAnswer, trackStepView } from '../utils/analytics';
import QuizZipcodeQuestion from './QuizZipCodeQuestion';
import QuizHomeQuestion from './QuizHomeQuestion';
import QuizOutdoorQuestion from './QuizOutdoorQuestion';
import QuizLifestyleQuestion from './QuizLifestyleQuestion';
import QuizProfilePhotoQuestion from './QuizProfilePhotoQuestion';
import QuizHouseholdQuestion from './QuizHouseholdQuestion';
import QuizPetExperienceQuestion from './QuizPetExperienceQuestion';
import { updateBuyerProfileQuizMutationDefinition } from '../utils/queryDefinitions';

const WIDE_QUIZ_QUESTION = FULL_PAGE_QUIZ_QUESTION_VARIANTS.WIDE;

export default function BuyerProfileQuiz() {
  const {
    conversationId,
    isModalOpen,
    closeModal,
    breederProfile,
    currentUser,
    isFetching,
    isUpdating,
    buyerProfileData
  } = useBuyerProfileQuiz();

  const buyerProfileId = get(currentUser, 'value.data.buyer_profile_id');

  const { mutateAsync: updateBuyerProfileQuiz } = useResourceMutation(
    updateBuyerProfileQuizMutationDefinition({ buyerProfileId, conversationId })
  );

  async function submit(originalFormState) {
    const skipped = get(originalFormState, FORM_SKIPS, []);
    let updatedFormState = { ...originalFormState };
    skipped.forEach(
      // need to remove each skipped attribute from the updatedFormState
      // eslint-disable-next-line no-return-assign
      field => (updatedFormState = remove(updatedFormState, field))
    );
    return updateBuyerProfileQuiz({ body: updatedFormState });
  }

  let orderedSteps = [];

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const profileIds = {
    breeder_profile_id: breederProfile?.id,
    buyer_profile_id: buyerProfileId
  };
  const {
    contextValue,
    formState,
    formErrors,
    reset,
    handleFormFieldChange,
    handleSubmission
  } = useForm({
    defaultFormState: {
      buyer_profile: { ...buyerProfileData },
      conversation_message: {
        conversation_id: conversationId,
        message_text: ''
      },
      skips: [],
      submitting: false
    },
    onSubmit: async () => {
      try {
        setIsFormSubmitting(true);
        const lastStep = orderedSteps[orderedSteps.length - 1];

        if (lastStep.inputName) {
          trackStepAnswer({
            step: lastStep,
            formState,
            properties: 'profileIds'
          });

          sendAnalyticsEvent(ANALYTICS_EVENTS.CONTACT_QUIZ_COMPLETED, {
            quiz_name: 'control',
            quiz_version: '1',
            ...profileIds
          });
        }

        await submit(formState);

        postReactNativeCloseWebViewMessage();
        const conversationUrl = currentUser.isBreeder
          ? `${BREEDER_ACTIVE_MESSAGES_PATH}/${conversationId}`
          : `${BUYER_ACTIVE_MESSAGES_PATH}/${conversationId}`;

        window.location.assign(conversationUrl);
      } catch (error) {
        setIsFormSubmitting(false);
        closeModal();
      }
    },
    mapFormStateToValidationSchema: () => {
      return {
        [FORM_BUYER_POSTAL_CODE]: CONSTRAINT_TYPES.ZIP
      };
    }
  });

  async function onSaveAndExitClick() {
    // Check formErrors here first
    await submit(formState);
    closeModal();
  }

  useEffect(() => {
    if (!isFetching && isModalOpen) reset();
  }, [isFetching, isModalOpen]);

  const isSubmitting = formState[FORM_SUBMITTING];
  useEffect(() => {
    if (!isSubmitting) {
      return;
    }
    // only submits after skipping is done
    handleSubmission();
  }, [isSubmitting]);

  if (!isModalOpen) return null;

  const isLoading = isFetching || contextValue.isSubmitting || isFormSubmitting;

  const commonProps = {
    icon: null,
    newDesignSystemStyles: true
  };
  const nestedBuyerProfileData = { buyer_profile: { ...buyerProfileData } };

  orderedSteps = [
    {
      inputName: FORM_BUYER_POSTAL_CODE,
      questionName: QUIZ_QUESTION_BUYER_POSTAL,
      Component: QuizZipcodeQuestion,
      isValid:
        !isEmpty(get(formState, FORM_BUYER_POSTAL_CODE)) &&
        isEmpty(formErrors[FORM_BUYER_POSTAL_CODE]),
      otherProps: commonProps,
      shouldSkip:
        !isEmpty(get(nestedBuyerProfileData, FORM_BUYER_POSTAL_CODE)) &&
        !isUpdating
    },
    {
      inputName: FORM_BUYER_HOME_TYPE,
      questionName: QUIZ_QUESTION_BUYER_HOME,
      Component: QuizHomeQuestion,
      otherProps: commonProps,
      isValid: !isEmpty(get(formState, FORM_BUYER_HOME_TYPE)),
      shouldSkip:
        !isEmpty(get(nestedBuyerProfileData, FORM_BUYER_HOME_TYPE)) &&
        !isUpdating
    },
    {
      inputName: FORM_BUYER_HOUSEHOLD,
      questionName: QUIZ_QUESTION_BUYER_HOUSEHOLD,
      Component: QuizHouseholdQuestion,
      otherProps: commonProps,
      isValid: !isEmpty(get(formState, FORM_BUYER_HOUSEHOLD)),
      shouldSkip:
        !isEmpty(get(nestedBuyerProfileData, FORM_BUYER_HOUSEHOLD)) &&
        !isUpdating
    },
    {
      inputName: FORM_BUYER_OUTDOOR_ACCESS,
      questionName: QUIZ_QUESTION_BUYER_OUTDOOR,
      Component: QuizOutdoorQuestion,
      otherProps: commonProps,
      isValid: !isEmpty(get(formState, FORM_BUYER_OUTDOOR_ACCESS)),
      shouldSkip:
        !isEmpty(get(nestedBuyerProfileData, FORM_BUYER_OUTDOOR_ACCESS)) &&
        !isUpdating
    },
    {
      inputName: FORM_BUYER_PET_EXPERIENCE,
      questionName: QUIZ_QUESTION_BUYER_PET_EXPERIENCE,
      Component: QuizPetExperienceQuestion,
      otherProps: commonProps,
      isValid: !isEmpty(get(formState, FORM_BUYER_PET_EXPERIENCE)),
      shouldSkip:
        !isEmpty(get(nestedBuyerProfileData, FORM_BUYER_PET_EXPERIENCE)) &&
        !isUpdating
    },
    {
      inputName: FORM_BUYER_PROFILE_PHOTO,
      questionName: QUIZ_QUESTION_BUYER_PROFILE_PHOTO,
      Component: QuizProfilePhotoQuestion,
      otherProps: commonProps,
      isValid: !isEmpty(get(formState, FORM_BUYER_PROFILE_PHOTO)),
      shouldSkip:
        !isUpdating &&
        get(nestedBuyerProfileData, `${FORM_BUYER_PROFILE_PHOTO}.id`)
    },
    {
      inputName: FORM_BUYER_LIFESTYLE,
      questionName: QUIZ_QUESTION_BUYER_LIFESTYLE,
      Component: QuizLifestyleQuestion,
      otherProps: commonProps,
      shouldSkip: isUpdating
    }
  ].filter(step => !step.shouldSkip);

  const lastStep =
    orderedSteps.length > 0 ? orderedSteps[orderedSteps.length - 1] : {};

  function handleNextClick(stepIndex) {
    const previousStep = orderedSteps[stepIndex - 1];

    submit(formState);

    if (previousStep.inputName) {
      trackStepAnswer({
        step: previousStep,
        formState,
        properties: profileIds
      });
    }
  }

  function handleSkipClick(stepIndex) {
    const previousStep = orderedSteps[stepIndex - 1];

    if (previousStep && previousStep.inputName) {
      trackStepAnswer({
        step: previousStep,
        formState,
        properties: profileIds
      });
    }
  }

  lastStep.otherProps = {
    ...lastStep.otherProps,
    handleNextClick: null,
    handleSkipClick,
    onSubmit: handleSubmission,
    submitBtnLabel: BUTTON_CONTINUE_LABEL,
    submitBtnClassName:
      lastStep.otherProps?.variant === WIDE_QUIZ_QUESTION ? null : 'w-100',
    autoNext: false,
    isLastStep: true
  };

  function handleStepShown(index) {
    const { questionName } = orderedSteps[index];
    trackStepView({ questionName, ...profileIds });
  }

  return (
    <FormContext.Provider
      value={{
        ...contextValue,
        handleClose: closeModal
      }}
    >
      <OverlayModal
        className="bg-white"
        hideCloseButton
        closeButtonLabel="Exit"
        onClose={closeModal}
      >
        <LoadingWrapper className="bg-sand h-100" isLoading={isLoading}>
          <FullPageQuiz
            className="bg-white z-max"
            formErrors={contextValue.formErrors}
            formState={contextValue.formState}
            handleFormFieldChange={handleFormFieldChange}
            isFormSubmitting={contextValue.isSubmitting}
            closeButtonLabel="Exit"
            orderedSteps={orderedSteps}
            onSaveAndExitClick={onSaveAndExitClick}
            onNextClick={handleNextClick}
            onSkipClick={handleSkipClick}
            onSubmit={handleSubmission}
            isLoading={isLoading}
            onStepShown={handleStepShown}
            progressBarVariant={PROGRESS_BAR_VARIANTS.REGULAR}
            newDesignSystemStyles
          />
        </LoadingWrapper>
      </OverlayModal>
    </FormContext.Provider>
  );
}
