import {
  ANALYTICS_EVENTS,
  ANALYTICS_USER_JOURNEYS,
  ANALYTICS_VIEWS,
  ANALYTICS_VIEW_FEATURES,
  EVENT_ACTION_IDS
} from 'dpl/constants/analytics';
import { USER_TYPES } from 'dpl/shared/constants/shared';
import { get } from 'dpl/shared/utils/object';
import {
  collectTrackedExperiments,
  sendAnalyticsEvent,
  trackConversionV2
} from 'dpl/util/analytics';

import { FORM_BREEDER_PROFILE_ID_NAME } from './constants';

export const INITIAL_INQUIRY_SOURCE_VIEWS = [
  ANALYTICS_VIEWS.FAVORITE_PUPPIES_PAGE,
  ANALYTICS_VIEWS.INITIAL_INQUIRY_SUCCESS_PAGE,
  ANALYTICS_VIEWS.PUPPY_PROFILE,
  ANALYTICS_VIEWS.PUPPY_SEARCH_RESULTS
];

export function trackInitialInquirySubmission({
  formState,
  buyerProfileId,
  conversationId,
  view,
  analytics = {}
}) {
  trackConversionV2(EVENT_ACTION_IDS.SUBMIT_INITIAL_INQUIRY);
  sendAnalyticsEvent(ANALYTICS_EVENTS.INITIAL_INQUIRY_SUBMITTED_BY_BUYER, {
    view,
    breeder_profile_id: get(formState, FORM_BREEDER_PROFILE_ID_NAME),
    buyer_profile_id: buyerProfileId,
    conversation_id: conversationId,
    user_journey: ANALYTICS_USER_JOURNEYS.BUYER_ACTIVATION_INITIAL_INQUIRY,
    view_feature: ANALYTICS_VIEW_FEATURES.ONE_CLICK_APPLY,
    ...analytics
  });
}

export function trackStepView(properties) {
  sendAnalyticsEvent(
    ANALYTICS_EVENTS.INITIAL_INQUIRY_QUESTION_VIEWED,
    properties
  );
}

function formatAnswerForEventProps(answer) {
  if (typeof answer === 'object') {
    return JSON.stringify(answer);
  }

  return answer;
}

export function trackStepAnswer({ step, formState, properties }) {
  let questionResponse = null;

  if (step.inputType === 'password') {
    questionResponse = '[redacted]';
  } else if (Array.isArray(step.inputName)) {
    // Join input values (e.g. "{firstName} {lastName}")
    questionResponse = step.inputName
      .map(inputName => get(formState, inputName, ''))
      .filter(Boolean)
      .map(formatAnswerForEventProps)
      .join(' ');
  } else {
    questionResponse = formatAnswerForEventProps(
      get(formState, step.inputName, '')
    );
  }

  sendAnalyticsEvent(ANALYTICS_EVENTS.INITIAL_INQUIRY_QUESTION_COMPLETED, {
    questionName: step.questionName,
    questionResponse,
    ...properties
  });
}

export function trackAccountCreation(userAccount) {
  sendAnalyticsEvent(ANALYTICS_EVENTS.ACCOUNT_CREATED_BY_BUYER, {
    user_journey: ANALYTICS_USER_JOURNEYS.BUYER_ACTIVATION_INITIAL_INQUIRY,
    account_type: USER_TYPES.BUYER,
    buyer_profile_id: get(userAccount, 'data.user.buyer_profile_id')
  });
}

export function trackInitialInquiryStart({
  buttonCta,
  analytics,
  buyerProfileId,
  breederProfileId
}) {
  sendAnalyticsEvent(ANALYTICS_EVENTS.BUTTON_CLICKED, {
    button_cta: buttonCta,
    user_journey: ANALYTICS_USER_JOURNEYS.BUYER_ACTIVATION_INITIAL_INQUIRY,
    ...analytics
  });

  sendAnalyticsEvent(ANALYTICS_EVENTS.INITIAL_INQUIRY_STARTED_BY_BUYER, {
    experiments: collectTrackedExperiments(),
    breeder_profile_id: breederProfileId,
    buyer_profile_id: buyerProfileId,
    user_journey: ANALYTICS_USER_JOURNEYS.BUYER_ACTIVATION_INITIAL_INQUIRY,
    ...analytics
  });
}
