import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import { pushParams } from 'dpl/actions/query_params';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { ADOPTER_APPLICATION_OPEN, BREED_ID } from 'dpl/constants/query_params';
import { addToQuery, parseQueryString } from 'dpl/util/queryString';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import {
  EVENT_CATEGORIES,
  EVENT_ACTION_IDS,
  ANALYTICS_EVENTS,
  ANALYTICS_USER_JOURNEYS
} from 'dpl/constants/analytics';
import {
  sendRudderstackEvent,
  trackEvent,
  trackConversion
} from 'dpl/util/analytics';
import { get } from 'dpl/shared/utils/object';
import Icon from 'dpl/common/components/Icon';
import { breederProfileAvailabilityResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import isFeatureEnabled from 'dpl/util/isFeatureEnabled';

const IS_REL_BUYER_APP_BREEDER_PROFILE_ENABLED = isFeatureEnabled(
  'rel_buyer_application_breeder_profile'
);

export default function ModalApplyButton({
  breederProfileUrl,
  breederProfileId,
  analytics,
  className,
  withIcon,
  customCopy
}) {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const { pageName } = useSelector(({ server }) => server);

  const handleApplyClick = compose(
    trackEvent(EVENT_CATEGORIES.APPLY_TO_BREEDER),
    trackConversion(EVENT_ACTION_IDS.APPLY_TO_BREEDER)
  )(() => {
    sendRudderstackEvent(ANALYTICS_EVENTS.BUYER_APP_STARTED, {
      breeder_profile_id: breederProfileId,
      buyer_profile_id: currentUser?.value?.data?.buyer_profile_id,
      user_journey: ANALYTICS_USER_JOURNEYS.BUYER_ACTIVATION,
      ...analytics
    });

    const [breederProfileEndpoint, hashParams] = breederProfileUrl.split('#');

    if (IS_REL_BUYER_APP_BREEDER_PROFILE_ENABLED) {
      window.location.href = `${breederProfileEndpoint}/contacts/buyer_application`;
      return;
    }

    if (pageName === 'breeder_profiles_show') {
      dispatch(
        pushParams({
          [ADOPTER_APPLICATION_OPEN]: true
        })
      );
      return;
    }

    const searchParams = addToQuery(
      { [ADOPTER_APPLICATION_OPEN]: true },
      window.location.search
    );

    const updatedHashParams = window.location.hash
      ? addToQuery(parseQueryString(window.location.hash), hashParams)
      : hashParams;

    window.location.href = `${breederProfileEndpoint}?${searchParams}${
      updatedHashParams ? `#${updatedHashParams}` : ''
    }`;
  });

  const breedId = useSelector(({ queryParams }) =>
    Number(queryParams[BREED_ID])
  );

  const breederAvailabilityData = useResourceQuery(
    breederProfileAvailabilityResourceDefinition(breederProfileId)
  );

  const {
    value: {
      data: { breeds }
    }
  } = breederAvailabilityData;

  const availableBreeds = breeds.filter(
    b => !b.snoozed && b.available && (breedId ? b.id === breedId : true)
  );

  const appliedTo = get(
    currentUser,
    'value.data.breeder_profiles_applied_to',
    []
  );

  const canApply =
    typeof currentUser?.isLoggedIn === 'boolean' &&
    !appliedTo.includes(breederProfileId);

  if (availableBreeds.length === 0 || !canApply) {
    return null;
  }

  const defaultCopy = (
    <>
      Apply&nbsp;
      <span className="d-none d-sm-inline">to Breeder</span>
    </>
  );

  return (
    <button
      className={classnames(
        'ModalApplyButton button button--primary fw-medium br3 bw0',
        className
      )}
      onClick={handleApplyClick}
      type="button"
    >
      {withIcon && <Icon className="mr3" name="application" />}
      {customCopy ? <span>{customCopy}</span> : defaultCopy}
    </button>
  );
}

ModalApplyButton.propTypes = {
  breederProfileUrl: PropTypes.string.isRequired,
  breederProfileId: PropTypes.number.isRequired,
  analytics: PropTypes.shape({
    breeder_preferred_status: PropTypes.string,
    page_number: PropTypes.number,
    position: PropTypes.number,
    view: PropTypes.string
  }),
  className: PropTypes.string,
  withIcon: PropTypes.bool,
  customCopy: PropTypes.string
};

ModalApplyButton.defaultProps = {
  analytics: {},
  className: 'pv3 w-100 flex justify-center mt3 mb6',
  withIcon: false,
  customCopy: null
};
