import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'dpl/common/components/Icon';

import { PUPPY } from '../utils/constants';

export default function HealthTierBadge({ healthTierTitle, type }) {
  const content =
    type === PUPPY
      ? `${healthTierTitle} parent health testing`
      : `${healthTierTitle} health testing`;
  return (
    <div className="flex items-center">
      <Icon
        className="mr1 no-repeat purple"
        name="health-badge-heart"
        width="16px"
        height="18px"
      />
      <span className="mid-gray f2 fw-medium">{content}</span>
    </div>
  );
}

HealthTierBadge.propTypes = {
  healthTierTitle: PropTypes.string.isRequired,
  type: PropTypes.string
};

HealthTierBadge.defaultProps = {
  type: null
};
