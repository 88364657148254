import React from 'react';

import { get } from 'dpl/shared/utils/object';
import Image from 'dpl/image_cropping/components/Image.next';
import FullPageQuizQuestion from 'dpl/components/FullPageQuiz/FullPageQuizQuestion';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import Icon from 'dpl/common/components/Icon';

import {
  FORM_BUYER_PROFILE_PHOTO,
  FORM_SKIPS,
  FORM_SUBMITTING
} from '../utils/constants';
import useBuyerProfileQuiz from '../hooks/useBuyerProfileQuiz';

const IMAGE_FAILED_MESSAGE = `Something went with your image. Please refresh page, or click continue to skip.`;

export default function QuizProfilePhotoQuestion(props) {
  const { breederProfile } = useBuyerProfileQuiz();
  const { formState, batchSetValue } = useFormContext();

  const profilePhoto = get(formState, FORM_BUYER_PROFILE_PHOTO, {});
  const skips = Array.from(get(formState, FORM_SKIPS, []));

  function handleFieldChange(photograph) {
    const idx = skips.indexOf(FORM_BUYER_PROFILE_PHOTO);
    if (idx > -1) {
      skips.splice(idx, 1);
    }

    batchSetValue({
      [FORM_BUYER_PROFILE_PHOTO]: photograph,
      [FORM_SKIPS]: skips
    });
  }

  return (
    <div className="BuyerProfileQuizProfilePhotoQuestion">
      <FullPageQuizQuestion
        title="Upload a profile photo."
        subtitle={`Let ${breederProfile.first_name} put a face to your name.`}
        showSkipBtn
        {...props}
        handleSkipClick={() => {
          batchSetValue({
            [FORM_SKIPS]: new Set([...skips, FORM_BUYER_PROFILE_PHOTO]),
            // eslint-disable-next-line react/prop-types
            [FORM_SUBMITTING]: props.isLastStep
          });
          // eslint-disable-next-line react/prop-types
          props.handleSkipClick();
        }}
      >
        <Image
          className="BuyerProfileQuizProfilePhotoQuestion__image"
          imageUploadClassName="bw2 b--dashed b--default br-100 hover-bg-cloud bg-white transition"
          photograph={profilePhoto}
          onSave={photograph => handleFieldChange(photograph)}
          imageFailedMessage={IMAGE_FAILED_MESSAGE}
          inputName={FORM_BUYER_PROFILE_PHOTO}
          openCropOverlayOnUpload
        >
          <div className="transform-center-all max-content pv1 ph1 br-100 bg-cloud">
            <Icon
              height="24x"
              width="24px"
              name="fetch-plus"
              className="midnight"
            />
          </div>
        </Image>
      </FullPageQuizQuestion>
    </div>
  );
}
