import React from 'react';

import FullPageQuizQuestion from 'dpl/components/FullPageQuiz/FullPageQuizQuestion';
import FullPageQuizTextInput from 'dpl/components/FullPageQuiz/FullPageQuizTextInput';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import { get } from 'dpl/shared/utils/object';

import {
  FORM_ACCOUNT_FIRST_NAME,
  FORM_ACCOUNT_LAST_NAME
} from '../utils/constants';

export default function QuizNameQuestion(props) {
  const { formState, handleFormFieldChange } = useFormContext();

  const firstName = get(formState, FORM_ACCOUNT_FIRST_NAME, '');
  const lastName = get(formState, FORM_ACCOUNT_LAST_NAME, '');

  return (
    <FullPageQuizQuestion
      className="InitialInquiryQuizNameQuestion"
      title="What’s your name?"
      subtitle="So we know what to call you."
      newDesignSystemStyles
      {...props}
    >
      <div className="row mb10">
        <div className="col-sm mb2 mb0-sm">
          <FullPageQuizTextInput
            type="text"
            id={FORM_ACCOUNT_FIRST_NAME}
            name={FORM_ACCOUNT_FIRST_NAME}
            placeholder="First name"
            autoComplete="given-name"
            value={firstName}
            onChange={handleFormFieldChange}
            labelText="First name"
            newDesignSystemStyles
          />
        </div>
        <div className="col-sm">
          <FullPageQuizTextInput
            type="text"
            id={FORM_ACCOUNT_LAST_NAME}
            name={FORM_ACCOUNT_LAST_NAME}
            placeholder="Last name"
            autoComplete="family-name"
            value={lastName}
            onChange={handleFormFieldChange}
            labelText="Last name"
            newDesignSystemStyles
          />
        </div>
      </div>
    </FullPageQuizQuestion>
  );
}
