import React from 'react';
import classnames from 'classnames';

import { get } from 'dpl/shared/utils/object';
import FullPageQuizQuestion from 'dpl/components/FullPageQuiz/FullPageQuizQuestion';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import InputCard from 'dpl/common/design_system/InputCard';

import {
  FORM_BUYER_HOME_TYPE,
  FORM_SKIPS,
  HOME_TYPES,
  ICON_CLASSNAMES,
  ICON_SIZE,
  SELECTED_ICON_CLASSNAMES
} from '../utils/constants';
import useBuyerProfileQuiz from '../hooks/useBuyerProfileQuiz';

const HOME_TYPE_ICON_PROPS = {
  [HOME_TYPES.HOUSE]: {
    name: 'fetch-home'
  },
  [HOME_TYPES.APARTMENT]: {
    name: 'fetch-apartment'
  },
  [HOME_TYPES.TOWNHOUSE]: {
    name: 'fetch-townhouse'
  },
  [HOME_TYPES.FARM]: {
    name: 'fetch-farm'
  },
  [HOME_TYPES.RV]: {
    name: 'fetch-rv'
  }
};

export default function QuizHomeQuestion(props) {
  const { formState, batchSetValue, setValue } = useFormContext();
  const { quizOptionsData } = useBuyerProfileQuiz();

  const homeType = get(formState, FORM_BUYER_HOME_TYPE) || '';
  const skips = Array.from(get(formState, FORM_SKIPS, []));

  function handleFieldChange(e) {
    const idx = skips.indexOf(FORM_BUYER_HOME_TYPE);
    if (idx > -1) {
      skips.splice(idx, 1);
    }

    batchSetValue({
      [FORM_BUYER_HOME_TYPE]: e.target.value,
      [FORM_SKIPS]: skips
    });
  }

  return (
    <div className="BuyerProfileQuizHomeQuestion">
      <FullPageQuizQuestion
        title="What type of home are you in?"
        subtitle="Select one option."
        showSkipBtn
        {...props}
        handleSkipClick={() => {
          setValue(FORM_SKIPS, new Set([...skips, FORM_BUYER_HOME_TYPE]));
          // eslint-disable-next-line react/prop-types
          props.handleSkipClick();
        }}
      >
        <ul className="flex flex-wrap justify-center tl">
          {quizOptionsData.home_types.map(type => (
            <li key={type.value}>
              <InputCard
                className={classnames(
                  'bg-white tertiary ba mh1 mv1',
                  homeType === type.value
                    ? 'b--midnight midnight'
                    : 'b--default'
                )}
                icon={{
                  ...HOME_TYPE_ICON_PROPS[type.value],
                  size: ICON_SIZE,
                  className:
                    homeType === type.value
                      ? SELECTED_ICON_CLASSNAMES
                      : ICON_CLASSNAMES
                }}
                type="radio"
                minHeight="110px"
                width="156px"
                inputProps={{
                  name: FORM_BUYER_HOME_TYPE,
                  value: type.value,
                  checked: homeType === type.value,
                  onChange: e => {
                    if (e.target.checked) {
                      handleFieldChange(e);
                    }
                  }
                }}
              >
                <span className="font-16">{type.label}</span>
              </InputCard>
            </li>
          ))}
        </ul>
      </FullPageQuizQuestion>
    </div>
  );
}
