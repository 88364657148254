import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'dpl/common/components/Icon';
import SmartImage from 'dpl/components/SmartImage';

const ACTION_POSITIONS = {
  TOP: 'top',
  BOTTOM: 'bottom'
};

const ACTION_VARIANTS = {
  DEFAULT: 'default',
  ICON_ONLY: 'icon-only'
};

const CARD_VARIANTS = {
  DEFAULT: 'default',
  WIDE: 'wide'
};

const INPUT_TYPES = {
  BUTTON: 'button',
  CHECKBOX: 'checkbox',
  RADIO: 'radio'
};

const OPTION_TYPE_NAMES = [INPUT_TYPES.CHECKBOX, INPUT_TYPES.RADIO];

function CardAction({ iconName, label, onClick, variant }) {
  const isIconOnlyVariant = variant === ACTION_VARIANTS.ICON_ONLY;

  return (
    <button
      type="button"
      className={classnames(
        'flex items-center justify-center gap-8 bg-white ba b--default pv2',
        isIconOnlyVariant ? 'ph2 br-100' : 'ph3 br-pill'
      )}
      aria-label={isIconOnlyVariant ? label : null}
      onClick={onClick}
    >
      {!isIconOnlyVariant && (
        <span className="font-14 fw-medium midnight">{label}</span>
      )}
      <Icon name={iconName} height="16px" width="16px" />
    </button>
  );
}

CardAction.propTypes = {
  iconName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(Object.values(ACTION_VARIANTS))
};

CardAction.defaultProps = {
  variant: ACTION_VARIANTS.DEFAULT
};

function getRootElement(type) {
  if (type === INPUT_TYPES.BUTTON) {
    return 'button';
  }

  if (OPTION_TYPE_NAMES.includes(type)) {
    return 'label';
  }

  return 'div';
}

export default function InputCard({
  backgroundImageUrl,
  children,
  className,
  type,
  minHeight,
  width,
  onClick,
  action,
  icon,
  avatar,
  inputProps,
  variant
}) {
  const Root = getRootElement(type);
  const hasOptionType = OPTION_TYPE_NAMES.includes(type);

  return (
    <Root
      className={classnames(
        'InputCard flex bg-center cover br12 pt5 pb5 pl5 pr5',
        type && `InputCard--${type}`,
        inputProps?.disabled && 'InputCard--disabled o-60',
        hasOptionType && 'native-focus-visible-outline no-select',
        variant === CARD_VARIANTS.DEFAULT && 'flex-column justify-between',
        className
      )}
      htmlFor={Root === 'label' ? inputProps?.id : null}
      style={{
        minHeight,
        width,
        backgroundImage: backgroundImageUrl
          ? `url('${backgroundImageUrl}')`
          : null
      }}
      onClick={onClick}
    >
      <div className="flex justify-between">
        {icon && (
          <div
            className={classnames(
              icon.className,
              'pv2 ph2 br-100 dib br-100 dib',
              variant === CARD_VARIANTS.WIDE && 'min-content-h'
            )}
          >
            <Icon
              name={icon.name}
              height={icon.size || '32px'}
              width={icon.size || '32px'}
            />
          </div>
        )}
        {avatar && (
          <div
            className={classnames(
              avatar.className,
              'br-100 dib min-content-h mr3'
            )}
          >
            <SmartImage
              Tag="div"
              crop
              lazy
              className="bg-center cover br-100"
              src={avatar.cover_photo_url}
              height={avatar.size || '32px'}
              width={avatar.size || '32px'}
            />
          </div>
        )}
        {action && action.position === ACTION_POSITIONS.TOP && (
          <div className="ml-auto">
            <CardAction {...action} />
          </div>
        )}
      </div>
      {hasOptionType && (
        <div className="relative">
          <span className="sr-only">
            <input {...inputProps} type={type} />
          </span>
        </div>
      )}
      <div className="flex justify-between items-end">
        {children && <div className="w-100">{children}</div>}
        {action &&
          (action.position === ACTION_POSITIONS.BOTTOM || !action.position) && (
            <div className="ml-auto">
              <CardAction {...action} />
            </div>
          )}
      </div>
    </Root>
  );
}

InputCard.propTypes = {
  backgroundImageUrl: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  minHeight: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(Object.values(INPUT_TYPES)),
  action: PropTypes.shape({
    iconName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(Object.values(ACTION_VARIANTS)),
    position: PropTypes.oneOf(Object.values(ACTION_POSITIONS))
  }),
  icon: PropTypes.shape({
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    size: PropTypes.string
  }),
  avatar: PropTypes.shape({
    className: PropTypes.string,
    cover_photo_url: PropTypes.string.isRequired,
    size: PropTypes.string
  }),
  inputProps: PropTypes.shape({
    disabled: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func
  }),
  variant: PropTypes.oneOf(Object.values(CARD_VARIANTS))
};

InputCard.defaultProps = {
  backgroundImageUrl: null,
  children: null,
  className: null,
  minHeight: null,
  width: null,
  onClick: null,
  onChange: null,
  type: null,
  action: null,
  icon: null,
  inputProps: null,
  variant: CARD_VARIANTS.DEFAULT,
  avatar: null
};
