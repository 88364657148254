import React from 'react';

import FullPageQuizQuestion from 'dpl/components/FullPageQuiz/FullPageQuizQuestion';
import FullPageQuizTextInput from 'dpl/components/FullPageQuiz/FullPageQuizTextInput';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import { get } from 'dpl/shared/utils/object';

import { FORM_BUYER_LIFESTYLE } from '../utils/constants';
import useBuyerProfileQuiz from '../hooks/useBuyerProfileQuiz';

export default function QuizLifestyleQuestion(props) {
  const { breederProfile, puppyName, buyerProfileData } = useBuyerProfileQuiz();
  const { formState, handleFormFieldChange } = useFormContext();

  const lifestyle = get(formState, FORM_BUYER_LIFESTYLE, '');

  return (
    <FullPageQuizQuestion
      className="BuyerProfileQuizLifestyleQuestion"
      title={
        buyerProfileData.initial_profile_complete
          ? 'Kick off your chat with a message.'
          : 'One last step, then start chatting.'
      }
      isValid
      subtitle={`Tell ${breederProfile.first_name} a little about yourself or ask a specific question about ${puppyName}.`}
      {...props}
    >
      <FullPageQuizTextInput
        Tag="textarea"
        className="mb1 no-resize"
        id={FORM_BUYER_LIFESTYLE}
        name={FORM_BUYER_LIFESTYLE}
        autoCapitalize="none"
        placeholder={`E.g., Hi! I work from home and am active. I’d love for ${puppyName} to join me on my hikes.`}
        value={lifestyle}
        onChange={handleFormFieldChange}
        labelText="Message"
        newDesignSystemStyles
      />
    </FullPageQuizQuestion>
  );
}
