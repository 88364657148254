import React from 'react';

import FullPageQuizQuestion from 'dpl/components/FullPageQuiz/FullPageQuizQuestion';
import FullPageQuizTextInput from 'dpl/components/FullPageQuiz/FullPageQuizTextInput';
import ErrorWrapper from 'dpl/form/components/ErrorWrapper';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import { get } from 'dpl/shared/utils/object';

import { FORM_ACCOUNT_PASSWORD } from '../utils/constants';

export default function QuizPasswordQuestion(props) {
  const { formState, formErrors, handleFormFieldChange } = useFormContext();

  const password = get(formState, FORM_ACCOUNT_PASSWORD, '');
  const errors = formErrors[FORM_ACCOUNT_PASSWORD];

  return (
    <FullPageQuizQuestion
      className="InitialInquiryQuizPasswordQuestion"
      title="Create a password."
      subtitle="Use at least 6 characters."
      newDesignSystemStyles
      {...props}
    >
      <ErrorWrapper errors={errors} newDesignSystemStyles>
        <FullPageQuizTextInput
          type="password"
          className="mb1"
          id={FORM_ACCOUNT_PASSWORD}
          name={FORM_ACCOUNT_PASSWORD}
          placeholder="Password"
          value={password}
          onChange={handleFormFieldChange}
          labelText="Password"
          hasErrors={errors && errors.length > 0 && password.length > 0}
          newDesignSystemStyles
        />
      </ErrorWrapper>
    </FullPageQuizQuestion>
  );
}
