import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import withToggle from 'dpl/decorators/withToggle';

function ClampedMultiLineTextToggleButton({ className, text, onClick }) {
  return (
    <button
      type="button"
      data-test-id="ClampedMultiLineText__moreButton"
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
      className={classnames('underline', className)}
    >
      {text}
    </button>
  );
}

ClampedMultiLineTextToggleButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

ClampedMultiLineTextToggleButton.defaultProps = {
  className: ''
};

class ClampedMultiLineText extends Component {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    numOfLines: PropTypes.number.isRequired,
    lineHeight: PropTypes.number.isRequired,
    text: PropTypes.string,
    className: PropTypes.string,
    /* eslint-enable react/no-unused-prop-types */
    moreText: PropTypes.string,
    lessText: PropTypes.string,
    moreButtonClassName: PropTypes.string,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func.isRequired,
    hideEllipses: PropTypes.bool,
    fadeToButton: PropTypes.bool,
    fadeToBackgroundColor: PropTypes.string,
    setInnerHTML: PropTypes.bool, // directly set innerHTML (for encoded entities :/)
    onClick: PropTypes.func, // to override default toggle behavior
    displayMoreText: PropTypes.bool
  };

  static defaultProps = {
    text: '',
    isOpen: false,
    className: '',
    moreText: 'Read More',
    lessText: 'Show Less',
    moreButtonClassName: null,
    onClick: null,
    setInnerHTML: false,
    hideEllipses: false,
    fadeToButton: false,
    fadeToBackgroundColor: '#ffffff',
    displayMoreText: true
  };

  static getDerivedStateFromProps(props, state) {
    const { lineHeight, numOfLines, text } = props;
    const { originalText } = state;

    const newText = (text || '').trim();

    if (originalText !== newText) {
      return {
        originalText: newText,
        maxHeight: lineHeight * numOfLines
      };
    }

    return null;
  }

  state = {
    originalText: '',
    maxHeight: 0,
    hasMoreContent: false
  };

  ref = createRef();

  computeHasMoreContent() {
    window.requestIdleCallback(() => {
      const { scrollHeight, clientHeight } = this.ref.current || {};
      if (scrollHeight - clientHeight > 1) {
        this.setState({ hasMoreContent: true });
      }
    });
  }

  componentDidMount() {
    if (this.state.originalText) {
      this.computeHasMoreContent();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.originalText !== this.state.originalText ||
      prevProps.isOpen !== this.props.isOpen
    ) {
      this.computeHasMoreContent();
    }
  }

  render() {
    const { originalText, maxHeight, hasMoreContent } = this.state;

    const {
      moreText,
      className,
      lessText,
      moreButtonClassName,
      fadeToBackgroundColor,
      isOpen,
      toggle,
      onClick,
      hideEllipses,
      fadeToButton,
      displayMoreText,
      setInnerHTML
    } = this.props;

    return (
      <div className={classnames('ClampedMultiLineText relative', className)}>
        <div
          ref={this.ref}
          style={{ maxHeight: !isOpen && `${maxHeight}px` }}
          className="overflow-hidden"
        >
          {setInnerHTML ? (
            <p
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: originalText }}
              className="ClampedMultiLineText__paragraphText overflow-hidden"
            />
          ) : (
            <p className="ClampedMultiLineText__paragraphText di">
              {originalText}
            </p>
          )}
          {displayMoreText && isOpen && (
            <ClampedMultiLineTextToggleButton
              text={lessText}
              onClick={onClick || toggle}
              className={moreButtonClassName}
            />
          )}
        </div>
        {hasMoreContent && !isOpen && displayMoreText && (
          <span
            className={classnames(
              'absolute bottom-0 right-0',
              { 'bg-white': !fadeToButton },
              { pl8: fadeToButton }
            )}
            style={{
              background: fadeToButton
                ? `linear-gradient(270deg, ${fadeToBackgroundColor} 73.25%, rgba(255, 255, 255, 0) 100%`
                : null
            }}
          >
            {!hideEllipses && <span>&nbsp;&hellip;&nbsp;</span>}
            <ClampedMultiLineTextToggleButton
              text={moreText}
              onClick={onClick || toggle}
              className={moreButtonClassName}
            />
          </span>
        )}
      </div>
    );
  }
}

export default withToggle({ propName: 'isOpen' })(ClampedMultiLineText);
