import React from 'react';

import { get } from 'dpl/shared/utils/object';
import FullPageQuizQuestion from 'dpl/components/FullPageQuiz/FullPageQuizQuestion';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import OptionGroup from 'dpl/quiz/option_group/components/OptionGroup';
import OptionGroupOption from 'dpl/quiz/option_group/components/OptionGroupOption';
import { OPTION_GROUP_VARIANTS } from 'dpl/quiz/option_group/utils/constants';

import { FORM_BUYER_HOUSEHOLD, FORM_SKIPS } from '../utils/constants';
import useBuyerProfileQuiz from '../hooks/useBuyerProfileQuiz';

export default function QuizHouseholdQuestion(props) {
  const { setValue, formState, batchSetValue } = useFormContext();
  const { quizOptionsData } = useBuyerProfileQuiz();

  const householdTags = get(formState, FORM_BUYER_HOUSEHOLD, []);
  const skips = Array.from(get(formState, FORM_SKIPS, []));

  function onSelectOption(value) {
    const updatedTags = [...householdTags];

    if (householdTags.includes(value)) {
      updatedTags.splice(updatedTags.indexOf(value), 1);
    } else {
      updatedTags.push(value);
    }

    const idx = skips.indexOf(FORM_BUYER_HOUSEHOLD);
    if (idx > -1) {
      skips.splice(idx, 1);
    }

    batchSetValue({
      [FORM_BUYER_HOUSEHOLD]: [...updatedTags],
      [FORM_SKIPS]: skips
    });
  }

  return (
    <div className="BuyerProfileQuizHouseholdQuestion">
      <FullPageQuizQuestion
        title="Who’s in your household?"
        subtitle="Select all that apply."
        showSkipBtn
        {...props}
        handleSkipClick={() => {
          setValue(FORM_SKIPS, new Set([...skips, FORM_BUYER_HOUSEHOLD]));
          // eslint-disable-next-line react/prop-types
          props.handleSkipClick();
        }}
      >
        <OptionGroup variant={OPTION_GROUP_VARIANTS.PILL}>
          {quizOptionsData.household_tags.map(tag => (
            <OptionGroupOption
              variant={OPTION_GROUP_VARIANTS.PILL}
              type="checkbox"
              key={tag.value}
              inputName={FORM_BUYER_HOUSEHOLD}
              title={tag.label}
              value={tag.value}
              onSelect={() => onSelectOption(tag.value)}
              isSelected={householdTags.includes(tag.value)}
            />
          ))}
        </OptionGroup>
      </FullPageQuizQuestion>
    </div>
  );
}
